import http from "@/utils/request.js";



export const getParticiInsuranceOption = (params) => {
	return http({
		url: '/azProductDividend/showProductAndYear',
		method: "get",
		params,
	});
};

export const getParticiInsuranceTable = (params) => {
	return http({
		url: '/azProductDividend/showProductList',
		method: 'post',
		data:params,
	});
};