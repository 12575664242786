import http from "@/utils/request.js";

//分支机构
export function getContentBranchList(data) {
    return http({
        url: "/content/branchList",
        method: "post",
        data,
    });
}
//分支机构---预览
export function getContentBranchListPro(data) {
    return http({
        url: "/content/getPreviewBranchcompanyList",
        method: "get",
        params: data,
    });
}

//分支机构区域
export function getContentAreaList(data) {
    return http({
        url: "/content/areaList",
        method: "get",
        params: data,
    });
}

//文件列表
export function getFileList(data) {
    return http({
        url: "/azFile/list",
        method: "post",
        data
    });
}
//文件列表---预览
export function getFileListPre(data) {
    return http({
        url: "/azFile/getPreviewFiledownloadList",
        method: "get",
        params: data,
    });
}


//文件列表时间
export function getPublishDate(data) {
    return http({
        url: "/content/getPublishDate",
        method: "post",
        data
    });
}
//文件列表时间---预览
export function getPublishDatePre(data) {
    return http({
        url: "/content/getProPublishDate",
        method: "get",
        params: data,
    });
}
//历史版本记录
export function gethistorList(data) {
    return http({
        url: "/history/list",
        method: "get",
        params: data,
    });
}
//历史版本记录---预览
export function gethistorListPre(data) {
    return http({
        url: "/proHistory/list",
        method: "get",
        params: data,
    });
}

