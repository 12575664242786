<template>
  <div>
    <div class="mobile-history">
      <header>
        <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
      </header>
      <crumbs :item="title"></crumbs>
      <div class="main-content">
        <div class="wrap">
          <div class="time">{{ "最新信息披露日期:" + time }}</div>
          <p class="title">红利实现率</p>
          <div class="tab">
            <div class="account">
              <van-cell-group>
                <van-field
                  v-model="productName.value.text"
                  label="产品名称"
                  right-icon="arrow"
                  placeholder="选择产品名称"
                  readonly
                  @click="showSelectPickerProductName(productName)"
                />
              </van-cell-group>
            </div>
            <div class="account">
              <van-cell-group>
                <van-field
                  v-model="yearsType.value.text"
                  label="分红年度"
                  right-icon="arrow"
                  placeholder="选择分红年度"
                  readonly
                  @click="showSelectPickeryearsType(yearsType)"
                />
              </van-cell-group>
            </div>
            <div class="content">
              <van-list
                v-model="loading"
                :finished-text="resultData"
                offset="0"
                :finished="loading"
                :immediate-check="false"
              >
                <div
                  class="item"
                  v-for="(item, index) in tableData"
                  :key="index"
                >
                  <div class="item-row" >
                    <div v-show="item.dividendAchRate">
                        <div class="titleText">现金红利实现率：</div> 
                        <div>
                          {{ item.dividendAchRate }}<br/> {{ item.dividendAchRate2 }}
                        </div>
                    </div>
                    <br/>
                    <div v-show="item.incDividendRate">
                      <div class="titleText">增额红利实现率：</div>
                      <div>
                        {{ item.incDividendRate }} <br/> {{ item.incDividendRate2 }}
                      </div>
                    </div>
                    <br/>
                    <div v-show="item.finalDividendRate">
                      <div class="titleText">终了红利实现率：</div>
                      <div>
                      {{ item.finalDividendRate }}<br/> {{ item.finalDividendRate2 }}
                      </div>  
                    </div>
                  </div>
                </div>
              </van-list>
              <div class="top_title">
                <p>说明:</p>
                <div>1.<p>分红年度2023适用于保单周年日在2024年1月1日（含）至2024年12月31日（含）期间满足分红条件的分红保险保单的红利发放。之前的分红年度以此类推。</p></div>
                <div>2.<p>红利实现率根据《关于印发一年期以上人身保险产品信息披露规则的通知》（银保监规[2022]24号）相关规定计算。采用现金红利分配方式的，披露现金红利实现率。采用增额红利分配方式的，披露增额红利实现率和终了红利实现率。各产品红利实现率计算方法：</p></div>
                <p style="margin-left:3%">&nbsp;&nbsp;（一）现金红利实现率</p> 
                <p style="margin-left:3%">&nbsp;&nbsp;实际派发的现金红利金额/红利利益演示的现金红利金额；</p>
                <p style="margin-left:3%">&nbsp;&nbsp;（二）增额红利实现率</p>
                <p style="margin-left:3%">&nbsp;&nbsp;实际派发的红利保额/红利利益演示的红利保额；</p>
                <p style="margin-left:3%">&nbsp;&nbsp;（三）终了红利实现率</p>
                <p style="margin-left:3%">&nbsp;&nbsp;实际派发的终了红利金额/红利利益演示的终了红利金额。</p>
                <p style="margin-left:3%">&nbsp;&nbsp;分红年度2023适用于保单周年日在2024年1月1日（含）至2024年12月31日（含）期间分红保险保单的红利发放。</p>
                <div>3.<p>红利实现率为每个分红产品所有有效保单合并计算的平均值，并不代表单个保险合同的实际红利分配水平，您所持有保险合同的实际红利分配金额以本公司发出的红利通知书为准。</p></div>
                <div>4.<p>分红保险的红利分配是不确定的，在某些年度红利可能为零。过去的红利实现率并不代表对未来的红利实现率的预期，未来的红利实现率可能低于或高于以上展示的历史红利实现率。</p></div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="qrcode">
          <div class="item">
            <img src="~@/static/images/public.jpg" alt="" />
            <span>公众号：安联人寿</span>
          </div>
          <div class="item">
            <img src="~@/static/images/service.jpg" alt="" />
            <span>服务号-安联在线</span>
          </div>
        </div>
        <div class="info">
          <p>© 安联人寿保险有限公司2012.All Rights Reserved.</p>
          <p class="beian">沪ICP备07011309号-1</p>
        </div>
      </div>
    </div>
    <!-- 选择 -->
    <selectPicker ref="selectPicker" @click="updataSelectPicker"></selectPicker>
  </div>
</template>

<script>
import selectPicker from "@/components/selectPicker.vue";
import crumbs from "@/components/crumbs.vue";
import {
  getParticiInsuranceTable,
  getParticiInsuranceOption,
} from "@/api/newsnew/particiInsurance";
import {getPublishDate } from "@/api/public";
export default {
  name: "particiInsuranceMobile",
  props: {},
  data() {
    return {
      title: [
          {
						title: "公开信息披露",
						link: "",
					},
					{
						title: "新型产品-分红险信息披露",
						link: "/#",
					}
      ],
      productName: {
        //产品名称
        value: {
          value: "",
          text: "",
          type:""
        },
        data: [],
       
      },
      yearsType:{
        //分红年度
          value: {
              value: "",
              text: ""
          },
          data: []
      },
      countType: "", //下拉框选中值
      tableData: [], //table数据
      loading: false, //加载
      time: "2022-09-20",
      resultData:""
    };
  },
  components: {
    crumbs,
    selectPicker,
  },
  methods: {
    //下拉选项触发函数
    async onChangeCountType() {
      console.log('this.productName.value.text======',this.productName.value.value);
      console.log('this.yearsType.value.text====',this.yearsType.value.value);
      if(!this.productName.value.value||!this.yearsType.value.value){
        if(!this.yearsType.value.value){
          this.$message.warning("请继续选择分红年度");
          this.getParticiInsuranceOption();
					return 
        }else if(!this.productName.value.value){
          this.$message.warning("请先选择产品");
					return 
        }
      }
      await this.getParticiInsuranceTable(this.productName.value.value,this.yearsType.value.value);
    },

    //获取下拉选项数据
    async getParticiInsuranceOption() {
      const [timeStamp, sign] = this.$getSign();
      const {
        data: { data },
      } = await getParticiInsuranceOption({ timeStamp, sign ,productName:this.productName.value.value,orderByType:"asc"});
      console.log('=================',this.productName.value)
        if(this.productName.value.value){
            this.yearsType.data = data.dividendYears.map(element => ({
                value: element.dividendYear,
                text: element.dividendYear
              }))
        }else{
          this.productName.data = data.productNames.map(element => ({
              value: element.productName,
              text: element.productName
            }))
        }
    },
    //获取历史公布利率数据
    async getParticiInsuranceTable(productName,yearsType) {
      this.loading = true;
      const [timeStamp, sign] = this.$getSign();
      const {
        data: { data },
      } = await getParticiInsuranceTable({ timeStamp, sign, productName:productName, dividendYear:yearsType});
      if(data.length<=0){
        this.resultData="暂无数据";
      }else{
        this.resultData=null;
      }
      this.tableData = data.map((item, index) => {
        const {
						dividendAchRate,
						finalDividendRate,
						incDividendRate,
            dividendAchRate2,
						finalDividendRate2,
						incDividendRate2,
						dividendYear
					} = item;
					return {
						id: index,
						dividendAchRate: dividendAchRate ? dividendAchRate: "",
						finalDividendRate: finalDividendRate ? finalDividendRate: "",
						incDividendRate: incDividendRate ? incDividendRate : "",
            dividendAchRate2: dividendAchRate2 ? dividendAchRate2: "",
						finalDividendRate2: finalDividendRate2 ? finalDividendRate2: "",
						incDividendRate2: incDividendRate2 ? incDividendRate2 : "",
						dividendYear: dividendYear ? dividendYear : ""
					};
      });
      this.loading = false;
    },
    //截取时间
			getStr(string, str) {
				if(string&&str){
					return string.split(str)[0];
				}else{
					return null;
				}
			},
    async getDisclosureDate(){
				const [timeStamp, sign] = this.$getSign();
				//获取披露日期
				const res = await getPublishDate({timeStamp,sign,contentType:this.$route.params.id});
        this.time = this.getStr(res.data.data, "T")
			},
    showSelectPickerProductName(params) {
      // if(!this.yearsType.value.value){
			// 		this.$message.warning("请继续选择分红年度");
			// 		return 
			// 	}
      this.$refs.selectPicker.parentMsg({
        struct: { ...params, type: "selectPickerProduct" },
      });
    },
    showSelectPickeryearsType(params) {
      if(!this.productName.value.text){
					this.$message.warning("请先选择产品");
					return 
				}
      this.$refs.selectPicker.parentMsg({
        struct: { ...params, type: "selectPickerYear" },
      });
    },
    updataSelectPicker(params) {
      if(params.struct.type == 'selectPickerProduct'){
        this.productName.value = params.struct.value;
        this.yearsType.value.value =null;
        this.yearsType.value.text=null;
        this.tableData=[];
      }else{
        this.yearsType.value = params.struct.value;
      }
      if(this.productName.value && this.yearsType.value){
        this.onChangeCountType();
      }
    },
  },
  async created() {
    // this.countType = localStorage.getItem("interestrateHistoryCovercode");
    // this.getParticiInsuranceMobileTable(this.countType);
    this.getDisclosureDate();//获取披露日期
    await this.getParticiInsuranceOption(); //获取选项数据
  },
};
</script>
<style lang='scss' scoped>
@function px2rem($px, $screen: 1920) {
  @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
}

@function px2vw($px) {
  @return calc(100vw * #{$px} / 1920);
}
 ::-webkit-scrollbar {
  width: 3px !important;
}

.mobile-history {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  padding-bottom: px2rem(30, 375);

  header {
    width: 100%;
    height: px2rem(50, 375);
    display: flex;
    align-items: center;
    padding-left: px2rem(15, 375);
    img {
      width: px2rem(75, 375);
      height: px2rem(30, 375);
    }
  }
  .main-content {
    width: 100%;
    padding: px2rem(10, 375) px2rem(10, 375);
    padding-bottom: px2rem(120, 375);
    height: 100%;
   overflow: hidden;
    .wrap {
      width: 100%;
      padding: 0;

      > p {
        text-align: center;
        font-size: px2rem(16, 375);
        margin: px2rem(10, 375) 0;
        color: #003781;
      }

      > .tab {
        //width: 60%;
        /* width: 129rem; */
        background: #fff;
        margin: auto;
        /* padding: 0  px2rem(10, 375) px2rem(10, 375); */
        // height: 52rem;
        /* margin-bottom: 6rem; */

        //账户
        .account {
          background: #edeff5;
          padding: px2rem(10, 375) px2rem(10, 375) px2rem(10, 375)
            px2rem(10, 375);
          font-size: px2rem(16, 375);
          /deep/ .van-field__label {
            width: unset;
          }
          /deep/ .van-field__control {
            font-size: calc(100vw / (375 / 10) * 12 / 10);
          }
          /deep/ .van-cell {
            padding: px2rem(6, 375) px2rem(12, 375);
            font-size: px2rem(12, 375);
          }
        }
        .content {
          height: px2rem(400, 375);
          overflow-x: hidden;
          // overflow-y: scroll;
          margin-top: px2rem(10, 375);
          .item {
            background: #edeff5;
            padding: px2rem(8, 375);
            margin-bottom: px2rem(10, 375);
            border-radius: px2rem(10, 375);
            font-size: px2rem(14, 375);
            .item-row {
              //display: flex;
              justify-content: space-between;
              .titleText{
                width: 50%;
              }
              div {
                display: flex;
                width: 100%;
                text-align: left;
              }


            }
          }
           .top_title{
            font-size:10px;
            font-family: 宋体;
            margin: 3% 13%;
            line-height: 1;
            height: 86%;
            > p{
              line-height: 1.7;
            }
            > div{
              
              display: flex;
              > p{
                margin-left: 2%;
              }
            }
          }
        }
      }
      .title{
        font-size: 11px;
      }
			.time{
				text-align: right;
				margin-right: 3%;
				margin-top: 2%;
        font-size: 10px;
			}
    }
  }
  .bottom {
    width: 100%;
    background-color: #fff;
    padding: px2rem(10, 375) 0;
    position: absolute;
    bottom: 0;
    z-index: 9999;
    .qrcode {
      display: flex;
      justify-content: space-evenly;
      margin: px2rem(30, 375) 0;
      display: none;

      .item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        img {
          width: px2rem(100, 375);
          height: px2rem(100, 375);
        }
        span {
          font-size: px2rem(13, 375);
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }
    }

    .info {
      p {
        font-size: px2rem(13, 375);
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;

        &.beian {
          color: RGBA(38, 77, 139, 1);
        }
      }
    }
  }
}
</style>